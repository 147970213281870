<template>
  <div class="list-info" id="watermark">
    <top-bar :title="'设备管理'" :left="true"></top-bar>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="" />
      <p>新增、添加</p>
    </div>
    <div class="list-search">
      <div class="searchValue">
        <input v-model="searchValue" placeholder="请输入设备名/负责人/编号" @keyup.enter="onSearch" type="search" value="搜索" class="search-content" />
        <img class="search-icon" :src="require('@/assets/img/search.png')" />
        <van-icon v-if="searchValue" class="clear-btn" @click="onCancel" name="clear" color="#999" size="16" />
      </div>
    </div>

    <div class="filterBar">
      <van-row>
        <van-col span="12">
          <p
            @click="dateBarShow = !dateBarShow"
            :style="{ color: dateBarShow?'#387FF5':'#666666'}"
          >
            安装时间
            <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="12">
          <p
            @click="typeShow = !typeShow"
            :style="{ color: typeShow?'#387FF5':'#666666'}"
          >
            设备类型
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow">
          <span>{{
            selectDate.beginDate == "" ? "最早" : selectDate.beginDate
          }}</span></van-col
        >
        <van-col span="2"><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow">
          <span>{{
            selectDate.endDate == "" ? "至今" : selectDate.endDate
          }}</span></van-col
        >
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>

    <van-popup
      v-model="beginDateShow"
      position="bottom"

    >
      <van-datetime-picker
        @confirm="beginDateConfim"
        @cancel="beginDateShow = false"
        type="date"
        title="选择年月日"
        :min-date="new Date(2017, 0, 1)"
        :max-date="new Date()"
      />
    </van-popup>
    <van-popup
      v-model="endDateShow"
      position="bottom"

    >
      <van-datetime-picker
        @confirm="endDateConfim"
        @cancel="endDateShow = false"
        type="date"
        title="选择年月日"
        :min-date="new Date(2017, 0, 1)"
        :max-date="new Date(2025, 10, 1)"
      />
    </van-popup>

    <select-type v-show="typeShow" :list="typeList" @selectLabel="selectLabel"></select-type>
<!--    <van-popup v-model="typeShow" position="bottom" >-->
<!--      <van-picker-->
<!--        title="设备类型"-->
<!--        show-toolbar-->
<!--        :columns="typeList"-->
<!--        value-key="label"-->
<!--        @confirm="typeConfirm"-->
<!--        @cancel="typeCancel"-->
<!--      />-->
<!--    </van-popup>-->
    <van-popup
      v-model="moreShow"
      position="right"
      :style="{ width: '90%', height: '100%' }"
      @open="moreClose"
    >
      <div style="margin-bottom: 50px">
        <van-cell title="设备类型">
          <span style="color: #fe5e5e">{{ moreTypeValue }}</span>
        </van-cell>
        <van-grid :border="false" :column-num="3">
          <van-grid-item v-for="value in typeList" :key="value.value">
            <van-tag
              size="large"
              @click="typeTagFn(value)"
              :color="value.label === moreTypeValue ? '#ffe1e1' : '#f0f2f5'"
              :text-color="value.label === moreTypeValue ? '#FE5E5E' : '#000'"
              >{{ value.label }}
            </van-tag>
          </van-grid-item>
        </van-grid>
      </div>
      <van-row>
        <van-col span="12">
          <van-button block @click="moreCancel">重置</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="danger" block @click="moreConfirm">确定</van-button>
        </van-col>
      </van-row>
    </van-popup>

    <div class="cont" v-show="!typeShow">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getDataList"
          offset="10"
          v-if="dataList.length > 0"
        >
          <div class="totalCount">共有&nbsp;<span>{{totalCount}}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
            <div class="title">
              <div class="title-left">
                {{ item.name }}{{item.coding ? '|'+item.coding : ''}}
              </div>
              <div class="title-right">
                <van-tag type="primary" text-color="#4581F8" color="rgba(78,124,245,0.12)" style="border-radius: 4px;padding: 5px 10px;margin-left: 12px;">{{item.typeStr}}</van-tag>
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url||require('@/assets/img/default.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/default.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text" style="font-weight: 600;">负责人：{{ item.charger }}</div>
                <div class="center-text">联系电话：{{ item.mobile }}<img :src="require('@/assets/img/mobile.png')" alt="" class="tel" @click.stop="getRealMobile(item.id)"/></div>
                <div class="bottom-text">安装日期：{{ item.locationDate }}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
            <div class="content-bottom">详细地址：{{ item.address }}</div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import { formatterDate } from "@/utils/utils";
import { getImageStream } from "@/utils";
import { mapMutations } from "vuex";
import {getDictTree,getDeviceMobile} from "@/utils/common"
import SelectType from '../activity/activity-type'

export default {
  name: 'device',
  components: {
    topBar,
    SelectType
  },
  data() {
    return {
      totalCount: 0,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      typeShow: false,
      moreShow: false,
      selectDate: {
        endDate: "",
        beginDate: "",
      },
      typeList: [],
      moreTypeValue: "",
      moreStatusValue: { label: "", value: "" },
      searchValue: "",
      loading: false,
      pullLoading: false,
      finished: false,
      limit: 5,
      page: 0,
      dataList: [],
      total: "",
    };
  },
  methods: {
    ...mapMutations,
    getInfo(id) {
      this.$router.push({ path: "/device-info", query: { id: id } });
    },
    goAdd() {
      this.$router.push("/device-info");
    },
    getRealMobile(id) {
      getDeviceMobile(id,function(data) {
        window.location.href = `tel:${data.mobile}`
      })
    },
    getDataList() {
      this.page++;
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/device/list"),
        method: "post",
        params: this.$http.adornParams({
          page: this.page, //当前页码
          limit: this.limit, //每页展示的记录数
          searchValue: this.searchValue, // 搜索框内容,以名字作为查询条件
          startLocationDate: this.selectDate.beginDate, //时间
          endLocationDate: this.selectDate.endDate,
          type: this.currentType, //类型
          orgId: this.$orgId,
          userId: this.$globalData.userInfo.userId,
        }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.pullLoading = false
            if (this.dataList.length == data.page.totalCount) {
              this.finished = true;
            }
            this.dataList = this.dataList.concat(data.page.list);
            this.totalCount = data.page.totalCount;
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },

    beginDateConfim(value) {
      let d = new Date(this.selectDate.endDate);
      if (
        this.selectDate.beginDate != "" &&
        d.getTime(d) < value.getTime(value)
      ) {
        return this.$toast.fail("开始时间不能比结束时间大哦");
      }
      this.selectDate.beginDate = formatterDate(value);
      this.beginDateShow = false;
    },
    endDateConfim(value) {
      let d = new Date(this.selectDate.beginDate);
      if (
        this.selectDate.endDate != "" &&
        d.getTime(d) > value.getTime(value)
      ) {
        return this.$toast.fail("结束时间不能比开始时间小哦");
      }
      this.selectDate.endDate = formatterDate(value);
      this.endDateShow = false;
    },
    changeDate() {
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    dateClose() {
      this.selectDate = {
        endDate: "",
        beginDate: "",
      };
    },
    selectLabel(e) {
      const { selectList } = e
      this.currentType = selectList
      this.typeShow = false
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    typeConfirm(value, index) {
      this.page = 0;
      this.dataList = [];
      this.currentType = value.value;
      this.getDataList();
      this.typeShow = false;
    },
    typeCancel() {
      this.page = 0
      this.dataList = []
      this.currentType = ''
      this.getDataList()
      this.typeShow = false
    },
    typeTagFn(val) {
      this.moreTypeValue = val.label;
      this.moreType = val.value;
    },
    moreCancel() {
      this.moreTypeValue = "";
      this.moreType = "";
      this.moreStatusValue.label = "";
      this.moreStatusValue.value = "";
    },
    moreConfirm() {
      this.dataList = [];
      this.page = 0;
      this.currentType = this.moreType;
      this.getDataList();
      this.moreShow = false;
    },
    moreClose() {
      this.typeList.forEach((item) => {
        if (this.currentType === item.value) {
          this.moreTypeValue = item.label;
        }
      });
    },
    statusTagFn(item) {
      this.moreStatusValue.label = item.label;
      this.moreStatusValue.value = item.value;
    },
    onPullDownRefresh(e) {
      this.finished=false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch(val) {
      this.page = 0;
      this.dataList = [];
      this.getDataList();
    },
    onCancel() {
      this.page = 0;
      this.dataList = [];
      this.searchValue = "";
      this.getDataList();
    },
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.$toast.loading({
      duration: 0,
      message: "加载中...",
      forbidClick: true,
    });
    this.getDataList();
  },
  mounted() {
    let that = this;
    getDictTree({ code: "deviceType" }, function (e) {
      that.typeList = e;
    });
  },
  name: "device",
};
</script>
 <style scoped>
 .bottom-text {
   margin-top: 20px !important;
   font-size: 24px !important;
 }
 .icon {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}
 .center-text {
   line-height: 45px;
   margin-top: 20px;
 }
 .tel {
   width: 40px;
   height: 40px;
   margin-left: 40px;
   line-height: 90px;
 }
 .list-info .content {
   background-color: #FFFFFF;
 }
 </style>
